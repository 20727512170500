import { MoveDir } from "@/domain/gameserver/move-player.model";
import { Character } from "./Character";
import GameServerClient from "@/infra/opagameserver/gameserver/GameServerClient";
import GameInput from "./GameInput";
import { Vector2 } from "@/domain/gameserver/vector-2.model";
import { MoveFailed } from "@/domain/gameserver/move-failed.model";
import { GuestType } from "@/domain/gameserver/guest-type.enum";

export class MyCharacter extends Character {

  public static readonly percentToMoveAgain = 0.5;

  private hasMoved = false;
  private lastT = 0;
  private timeout: NodeJS.Timeout | null = null;

  constructor(params: {
    id: string,
    scene: Phaser.Scene,
    x: number,
    y: number,
    characterName: string,
    moveDir?: MoveDir,
    gender?: "male" | "female",
    frame?: string | number,
    playersDepth: number,
    isGuest?: boolean,
    guestType?: GuestType,
    emoji?:string,
    emojiSentTime:number,
    emojiTemporary:boolean,
    emojiActive:boolean,
    tileSize: Vector2
  }) {
    super({
      ...params,
      isItMe: true,
    });
    
    GameServerClient.onPlayerMoveFailedEvt.subscribe(this.moveFailed.name, this.moveFailed.bind(this));
    GameInput.onMoveEvt.subscribe(this.onMoveKeyPressed.name, this.onMoveKeyPressed.bind(this));
  }

  protected onNextPos(nextPos: {
    t: number,
    pos: Vector2,
  }) {
    if (this.desiredPositions.length > 1) return;
    if ((this.lastT ?? 0) < MyCharacter.percentToMoveAgain && nextPos!.t >= MyCharacter.percentToMoveAgain) {
      this.hasMoved = false;
    }
    this.lastT = nextPos?.t ?? 0;
  }

  protected onReachedDestination(pos: Vector2, time:number): void {
    super.onReachedDestination(pos, time);
    if (this.desiredPositions.length > 1) return;
    this.hasMoved = false;
  }

  onMoveKeyPressed(direction: MoveDir) {
    if (this.desiredPositions.length > 1 || this.hasMoved) return;
    this.hasMoved = true;
    const nextDesiredPos = this.desiredPositions[0];
    if (nextDesiredPos == null) {
      GameServerClient.movePlayer({ direction });
      return;
    }
    
    const res = nextDesiredPos?.lerp();
    if (res == null) {
      this.hasMoved = false;
      return;
    }

    if (this.hasMoved && res!.t < MyCharacter.percentToMoveAgain) return;
    GameServerClient.movePlayer({ direction });
  }

  destroy(): void {
    GameServerClient.onPlayerMoveFailedEvt.unsubscribe(this.moveFailed.name);
    GameInput.onMoveEvt.unsubscribe(this.onMoveKeyPressed.name);
    super.destroy();
  }

  public moveFailed(data: MoveFailed) {
    this.hasMoved = true;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.hasMoved = false;
    }, data.delayToMoveMs);
  }

  public teleport(params: { pos: Vector2; moveDir: MoveDir; }) {
    super.teleport(params);
    this.hasMoved = false;
  }

}