import { Vector2 } from "@/domain/gameserver/vector-2.model";

export class NameObject extends Phaser.GameObjects.Text {

  private follow: Phaser.GameObjects.Sprite;
  private diffPos: Vector2;

  private background: Phaser.GameObjects.Graphics;
  private tileSize: Vector2;

  constructor(params: {
    scene: Phaser.Scene;
    x: number;
    y: number;
    text: string;
    highlight: 'default' | 'guest' | 'me';
    follow: Phaser.GameObjects.Sprite;
    tileSize: Vector2;
  }) {
    let color = params.highlight === 'me' ? '#b34d00' : 'black';
    if (params.highlight === 'guest') color = '#00b3b3';

    super(params.scene, params.x, params.y, params.text.split(" ")[0], {
      align: 'center',
      color,
      fontSize: '12px',
      fontFamily: 'KomikaKaps'
    });

    this.tileSize = params.tileSize;
    this.follow = params.follow;
    this.scene.add.existing(this);
    this.diffPos = new Vector2({
      x: params.x - params.follow?.x,
      y: params.y - params.follow?.y,
    });
    
    this.setOrigin(0.49, 0.75);
    this.setPadding(3,2,3,2);
  }

  private getDepth(x:number, y:number) {
    const DEPTH = 11000;
    const strX = Math.floor(this.x / this.tileSize.x);
    const strY = Math.floor(this.y / this.tileSize.y);
    return DEPTH + Number(`${strX}${strY}`) + 1;
  }

  public update(time: number, delta: number) {
    this.x = this.follow.x + this.diffPos.x;
    this.y = this.follow.y + this.diffPos.y;
     this.setDepth(this.getDepth(this.x, this.y));
    this.drawRoundedRect();
  }

  public drawRoundedRect() {
    const paddingX = 2;
    const paddingY = 0;
    this.background?.destroy();
    this.background = this.scene.add.graphics();
    this.background.fillStyle(0x000000, 0.5);
    this.background.fillRoundedRect(
      (this.x - this.width / 2 -paddingX/2) -0.5,
      ((this.y - 3) - this.height / 2 - paddingY/2)-0.5,
      this.width + paddingX +2,
      this.height + paddingY +2,
      4
    )
    this.background.fillStyle(0xffffff, 1);
    this.background.fillRoundedRect(
      this.x - this.width / 2 -paddingX/2,
      (this.y - 3) - this.height / 2 - paddingY/2,
      this.width + paddingX,
      this.height + paddingY,
      4
    );
    
    //this.background.setAlpha(0.5);
    this.background.setDepth(this.getDepth(this.x, this.y)-1);
  }

  destroy(fromScene?: boolean): void {
    this.background?.destroy();
    super.destroy(fromScene);
  }

}