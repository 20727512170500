import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "chat-ui" }
const _hoisted_2 = { class: "chat-ui-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.chatStore.chatHistory, (message, i) => {
        return (_openBlock(), _createBlock($setup["ChatMessageUI"], {
          style: _normalizeStyle({
          'align-self': message.getAuthorUserId() == $setup.LoggedUserStorage.getUser()?.getId() ? 'flex-end' : 'flex-start'
        }),
          key: `${message.getAuthorUserId()}-${message.getCreatedAtUtc()}`,
          "is-it-me": message.getAuthorUserId() == $setup.LoggedUserStorage.getUser()?.getId(),
          userName: message.getAuthorName(),
          message: message.getMessage(),
          time: message.getCreatedAtUtc()
        }, null, 8 /* PROPS */, ["style", "is-it-me", "userName", "message", "time"]))
      }), 128 /* KEYED_FRAGMENT */)),
      _createCommentVNode(" <LocalizedText class=\"no-messages\" v-if=\"chatStore.chatHistory.length <= 0\" textKey=\"chat.noMessages\"></LocalizedText> ")
    ]),
    _createElementVNode("div", {
      class: "chat-ui-input",
      onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.chatInput?.setFocus(true)))
    }, [
      _createVNode($setup["ChatInput"], {
        ref: "chatInput",
        labelKey: "chat.input",
        onFocus: _cache[0] || (_cache[0] = ($event: any) => ($setup.chatStore.onToggleChat($setup.ToggleChatState.FOCUSED))),
        onBlur: _cache[1] || (_cache[1] = ($event: any) => ($setup.chatStore.onToggleChat($setup.ToggleChatState.UNFOCUSED))),
        onKeydown: _withKeys($setup.chatStore.sendPlaceMessage, ["enter"]),
        "onUpdate:modelValue": _cache[2] || (_cache[2] = (v) => $setup.chatStore.typingMessage = v),
        modelValue: $setup.chatStore.typingMessage ?? ''
      }, null, 8 /* PROPS */, ["onKeydown", "modelValue"]),
      _createVNode($setup["ChatButton"], {
        style: {"width":"fit-content"},
        class: "chat-button",
        labelKey: "chat.send",
        onClick: _cache[3] || (_cache[3] = () => $setup.chatStore.sendPlaceMessage())
      })
    ])
  ]))
}