import { ChatLocation, ChatMessage } from "@/domain/gameserver/send-message.model";
import { ChatEventBus, ToggleChatState } from "@/infra/event_bus/ChatEventBus";
import GameServerClient from "@/infra/opagameserver/gameserver/GameServerClient";
import { defineStore } from "pinia";

export interface IChatState {
  chatHistory: ChatMessage[];
  typingMessage: string;
  isChatFocused: boolean;
}

export const useChatStore = defineStore("chat_store", {
  state: (): IChatState => ({
    chatHistory: [],
    typingMessage: "",
    isChatFocused: false
  }),
  actions: {
    sendPlaceMessage() {
      GameServerClient.sendChatMessage({
        message: this.typingMessage,
        location: ChatLocation.PLACE,
      });
      this.typingMessage = "";
    },
    onToggleChat(toggleChatState: ToggleChatState) {
      ChatEventBus.toggleChat(toggleChatState);
    },
  }
});