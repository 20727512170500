import { JsonObject, JsonProperty } from "typescript-json-serializer";

export enum MoveDir {
  RIGHT='right',
  LEFT='left',
  UP='up',
  DOWN='down',
}

@JsonObject()
export class MovePlayerModel {
  @JsonProperty()
  direction: MoveDir;
}