// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.chat-ui[data-v-12b6297a] {
  position: absolute;
  bottom: 0px;
  right: 0px;

  height: calc(100svh - 64px);
  width: 350px;
  z-index: 100;

  display: flex;
  flex-direction: column;
  padding: 16px;
}
.chat-ui-content[data-v-12b6297a] {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 16px;
  gap: 4px;
  flex-grow: 1;
}
.chat-ui-input[data-v-12b6297a] {
  display: flex;
  gap: 4px;
}
.no-messages[data-v-12b6297a] {
  font-weight: 700;
  color: white;
}
.chat-ui-input button.chat-button .rounded-button[data-v-12b6297a] {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.70) 20.000000298023224%, rgba(240, 229, 255, 0.70) 100%);
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.70);
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 68px;
}

`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
