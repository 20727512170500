import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["CommonModal"], {
    teleport: true,
    ref: "modal",
    "is-full-screen": true
  }, {
    default: _withCtx(() => [
      ($setup.iframeUrl)
        ? (_openBlock(), _createElementBlock("iframe", {
            key: 0,
            class: "interaction-iframe",
            src: $setup.iframeUrl,
            sandbox: "allow-same-origin allow-scripts"
          }, null, 8 /* PROPS */, _hoisted_1))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */))
}