import Constants from "@/core/constants/constants";
import { DesiredPos } from "./DesiredPos";
import { Vector2 } from "@/domain/gameserver/vector-2.model";
import { MoveDir } from "@/domain/gameserver/move-player.model";
import GameInput from "./GameInput";
import GameServerClient from "@/infra/opagameserver/gameserver/GameServerClient";
import { NameObject } from "./NameObject";
import { EmojiObject } from "./EmojiObject";
import { parseRbsp } from "livekit-client";
import { GuestType } from "@/domain/gameserver/guest-type.enum";

enum CharAnimation {
  front_walking = "front_walking",
  left_walking = "left_walking",
  right_walking = "right_walking",
  back_walking = "back_walking",
  front_idle = "front_idle",
}

class CharAnimationUtils {
  static fromMoveDir(moveDir?: MoveDir): CharAnimation {
    switch (moveDir) {
      case MoveDir.DOWN:
        return CharAnimation.front_walking;
      case MoveDir.LEFT:
        return CharAnimation.left_walking;
      case MoveDir.RIGHT:
        return CharAnimation.right_walking;
      case MoveDir.UP:
        return CharAnimation.back_walking;
      default:
        return CharAnimation.front_idle;
    }
  }
}

export class Character extends Phaser.GameObjects.Sprite {

  public static readonly maxDesiredPositionsToTeleport = 5;

  public id: string;
  public currentAnimation: CharAnimation;
  public characterName: string;
  private readonly nameObj: NameObject;
  public readonly isItMe: boolean;
  public readonly isGuest: boolean;
  public guestType: GuestType | null;
  public readonly gender: "male" | "female";
  public shadow:Phaser.GameObjects.Image;
  public playersDepth = 0;
  protected reachedPositionTime:number|null = 0;
  protected desiredPositions: DesiredPos[] = [];
  private readonly emojiObj: EmojiObject;
  
  
  constructor(params: {
    id: string,
    scene: Phaser.Scene,
    isItMe?: boolean,
    x: number,
    y: number,
    characterName: string,
    moveDir?: MoveDir,
    gender?: "male" | "female",
    frame?: string | number,
    playersDepth: number,
    isGuest?: boolean,
    guestType?: GuestType;
    emoji?:string,
    emojiSentTime:number,
    emojiTemporary:boolean,
    emojiActive:boolean,
    tileSize: Vector2
  }) {
    super(params.scene, params.x, params.y, "", params.frame);
    if (!this) return;
    
    this.playersDepth = params.playersDepth;

    this.shadow = this.scene.add.image(this.x, this.y+15, "shadow");
    this.shadow.setScale(0.5);

    this.scale = 0.5;
    this.id = params.id;
    this.characterName = params.characterName;
    this.scene.add.existing(this);
    this.isItMe = params.isItMe ?? false;
    this.isGuest = params.isGuest ?? false;
    this.guestType = params.guestType ?? null;
    this.nameObj = new NameObject({
      follow: this,
      highlight: this.getCharacterType(),
      scene: this.scene,
      text: this.characterName,
      x: this.x,
      y: this.y - 37,
      tileSize: params.tileSize
    })
    this.emojiObj = new EmojiObject({
      follow:this,
      scene: this.scene,
      emoji: params.emoji,
      temporary: params.emojiTemporary,
      sentTime:params.emojiSentTime,
      active:params.emojiActive,
      x: this.x,
      y: this.y -72,
      tileSize: params.tileSize
    });
    
    
    this.gender = params.gender ?? "male";
    this.bindAnimations();
    this.changeAnimation(CharAnimationUtils.fromMoveDir(params.moveDir));
  }

  update(time: number, delta: number) {
    
    
    if (this.desiredPositions.length > Character.maxDesiredPositionsToTeleport) {
      this.desiredPositions = [this.desiredPositions.at(-1)!];
      return;
    }

    if (this.desiredPositions.length > 0) {
      const diffX = this.desiredPositions[0].getX() - this.x;
      const diffY = this.desiredPositions[0].getY() - this.y;

      if (diffX > 0) this.changeAnimation(CharAnimation.right_walking)
      else if (diffX < 0) this.changeAnimation(CharAnimation.left_walking)
      else if (diffY < 0) this.changeAnimation(CharAnimation.back_walking);
      else if (diffY > 0) this.changeAnimation(CharAnimation.front_walking)
      this.anims.play(this.currentAnimation, true);
      const nextDesiredPos = this.desiredPositions[0];
      if (!nextDesiredPos.hasStart()) nextDesiredPos.setStart({
        initialPos: this.getPosition(),
        startMoment: new Date(),
      });

      const nextPos = nextDesiredPos.lerp();
      this.onNextPos(nextPos);      

      this.x = nextPos!.pos.x;
      this.y = nextPos!.pos.y;
      if (nextPos!.t >= 1) {
        this.x = this.desiredPositions[0].getX();
        this.y = this.desiredPositions[0].getY();
        this.desiredPositions = this.desiredPositions.slice(1);
        this.onReachedDestination(this.getPosition(), new Date().getTime());
      } else {
        this.reachedPositionTime = null
      }
    } //else this.anims.stopOnFrame(this.anims.currentAnim!.frames[0]);

    if (this.reachedPositionTime != null && new Date().getTime() > this.reachedPositionTime + 250) {
      this.reachedPositionTime = null;
      this.changeAnimation(CharAnimation.front_idle);
    }

    //set player depth by the y position
    this.setDepth(this.playersDepth + this.y);

    // updates extras apos X e Y
    this.nameObj.update(time, delta);
    this.emojiObj.update(time, delta);
    this.shadow.setPosition(this.x+1, this.y+22);

  }

  bindAnimations() {
    this.anims.create({
      key: CharAnimation.front_walking,
      frames: this.anims.generateFrameNumbers(`char_demo_front_walking_${this.gender}`),
      frameRate: 60,
      repeat: -1,
    });

    this.anims.create({
      key: CharAnimation.left_walking,
      frames: this.anims.generateFrameNumbers(`char_demo_left_walking_${this.gender}`),
      frameRate: 60,
      repeat: -1,
    });

    this.anims.create({
      key: CharAnimation.right_walking,
      frames: this.anims.generateFrameNumbers(`char_demo_right_walking_${this.gender}`),
      frameRate: 60,
      repeat: -1,
    });

    this.anims.create({
      key: CharAnimation.back_walking,
      frames: this.anims.generateFrameNumbers(`char_demo_back_walking_${this.gender}`),
      frameRate: 60,
      repeat: -1,
    });

    this.anims.create({
      key: CharAnimation.front_idle,
      frames: this.anims.generateFrameNumbers(`char_demo_front_idle_${this.gender}`),
      frameRate: 7,
      repeat: -1,
    });
  }

  changeAnimation(anim: CharAnimation) {
    if (this.currentAnimation == anim) return;
    this.currentAnimation = anim;
    this.play(anim);
    // if (this.desiredPositions.length <= 0 && this.anims.currentAnim) {
    //   this.anims.stopOnFrame(this.anims.currentAnim!.frames[0]);
    // }
  }

  public getPosition(): Vector2 {
    return new Vector2({
      x: this.x,
      y: this.y,
    });
  }

  protected onNextPos(params: {
    t: number;
    pos: Vector2;
  } | null): void {}

  protected onReachedDestination(pos: Vector2, time:number): void {
    this.reachedPositionTime = time;
  }

  public addDesiredPosition(desiredPos: DesiredPos) {
    const lastPos = this.desiredPositions.at(-1);
    if (this.desiredPositions.length > Character.maxDesiredPositionsToTeleport) {
      this.x = lastPos!.getX();
      this.y = lastPos!.getY();
      this.desiredPositions = [];
      return;
    }
    if (lastPos?.getX() == desiredPos.getX() && lastPos?.getY() == desiredPos.getY()) return;
    this.desiredPositions.push(desiredPos);
  }

  public teleport(params: {
    pos: Vector2;
    moveDir: MoveDir;
  }) {
    this.desiredPositions = [];
    this.x = params.pos.x;
    this.y = params.pos.y;
    this.changeAnimation(CharAnimationUtils.fromMoveDir(params.moveDir));
  }

  public setEmoji(params:{
    emoji:string,
    sentTime:number,
    temporary:boolean,
    active:boolean
  }) {
    let emoji = params.emoji;
    let sentTime = params.sentTime;
    let temporary = params.temporary;
    let active = params.active;
    this.emojiObj.setEmoji({emoji, sentTime, temporary, active});
  }

  public clearEmoji() {
    this.emojiObj.clear();
  }

  destroy() {
    this.nameObj?.destroy();
    this.emojiObj?.destroy();
    this.shadow.destroy();
    super.destroy();
  }

  public getCharacterType(): 'me' | 'default' | 'guest' {
    if (this.isItMe) return 'me';
    if (this.isGuest) return 'guest';
    return 'default';
  }
}
